const apiConfig = {
  auth: {
    signUp: 'auth/signup/',
    login: 'auth/jwt/token/',
    logout: 'auth/logout/',
    refresh: 'auth/jwt/token/refresh/',
    authenticationCheck: 'auth/authentication-check/',
    resetPasswordRequest: 'auth/request-password-reset/',
    resetPasswordVerify: 'auth/verify-password-reset-token/',
    resetPassword: 'auth/reset-password/',
    sendVerifyEmail: 'auth/regenerate-email-token/',
    userInfo: 'auth/user-info/',
    emailVerificationCheck: 'auth/email-verification-check/',
    enroll: 'courses/enroll/',
    verifyEmail: 'auth/verify-email-token/',
    jitsiJWT: 'auth/generate-jitsi-jwt/',
    setOnboarding: 'auth/onboarding/',
    sendTimezone: 'auth/timezone/',
    readPetLetter: 'auth/read-pet-letter/',
  },
  courses: {
    getAllCourses: '/courses/list/',
    create: '/courses/create/',
    edit: (courseId: string) => `/courses/${courseId}/edit/`,
    delete: (courseId: string) => `/courses/${courseId}/delete/`,
    invite: (courseId: string) => `/courses/${courseId}/invite/`,
    CourseInfo: (courseId: string) => `/courses/${courseId}/`,
    listStudents: (courseId: string) => `/courses/${courseId}/students/`,
    getStudentsXPLevels: (courseId: string) =>
      `/courses/${courseId}/students/xp-levels/`,
    removeStudent: (courseId: string) => `/courses/${courseId}/student/remove/`,
    addStudent: (courseId: string) => `/courses/${courseId}/invite/mail/`,
    listLessons: (courseId: string) => `/courses/${courseId}/lessons/`,
    archive: (courseId: string) => `/courses/${courseId}/archive/`,
    unarchive: (courseId: string) => `/courses/${courseId}/unarchive/`,
  },
  quizzes: {
    getAll: '/quizzes/',
    handleQuiz: (quizId: string) => `/quizzes/${quizId}/`,
    questions: (quizId: string) => `/quizzes/${quizId}/questions/`,
    handleQuestion: (quizId: string, questionId: string) =>
      `/quizzes/${quizId}/questions/${questionId}/`,
    answers: (quizId: string, questionId: string) =>
      `/quizzes/${quizId}/questions/${questionId}/answers/`,
    handleAnswer: (quizId: string, questionId: string, answerId: string) =>
      `/quizzes/${quizId}/questions/${questionId}/answers/${answerId}/`,
    changeOrder: (quizId: string) =>
      `/quizzes/${quizId}/questions/change-order/`,
    getQuizData: (quizId: string) => `/quizzes/${quizId}/`,
    folders: '/quizzes/folders/',
    handleFolder: (folderId: string) => `/quizzes/folder/${folderId}/`,
  },
  lessons: {
    list: '/lessons/list/',
    create: '/lessons/create/',
    fetchLesson: (lessonId: string) => `/lessons/${lessonId}/`,
    checkForExistingLesson: (courseId: string) =>
      `/lessons/check-existing/${courseId}/`,
    end: (lessonId: string) => `/lessons/${lessonId}/end/`,
    addStudent: (lessonId: string) => `/lessons/${lessonId}/add/`,
    launchQuiz: (lessonId: string, quizId: string) =>
      `/lessons/${lessonId}/quiz/${quizId}/launch/`,
    submitQuizMode: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/submit-quiz-mode/`,
    registerStudentQuizParticipation: (
      lessonId: string,
      quizSessionId: string
    ) => `/lessons/${lessonId}/quiz-session/${quizSessionId}/participants/`,
    fetchQuizStatus: (lessonId: string, quizSessionId: string) => `/lessons/${lessonId}/quiz-session/${quizSessionId}/status/`,
    submitAnswer: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/submit-answer/`,
    goToNextQuestion: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/next-question/`,
    showQuizResults: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/show-quiz-results/`,
    endQuiz: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/end-quiz/`,
    startQuiz: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/start-quiz/`,
    joinQuiz: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/join-quiz/`,
    getActiveQuiz: (lessonId: string) =>
      `/lessons/${lessonId}/check-active-quiz/`,
    getCurrentQuestion: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/current-question/`,
    editQuizSessionResponseCorrectness: (lessonId: string, quizSessionId: string) =>
      `/lessons/${lessonId}/quiz-session/${quizSessionId}/edit-response-correctness/`,
    startBadgeReward: (lessonId: string) =>
      `/lessons/${lessonId}/reward-badge/launch/`,
    startUnderstandingCheck: (lessonId: string) =>
      `/lessons/${lessonId}/start-understanding-check/`,
    submitUnderstandingCheck: (lessonId: string) =>
      `/lessons/${lessonId}/submit-understanding-check/`,
    endUnderstandingCheck: (lessonId: string) =>
      `/lessons/${lessonId}/end-understanding-check/`,
    listStudents: (lessonId: string) => `/lessons/${lessonId}/students/`,
    launchBadgeReward: (lessonId: string) =>
      `/lessons/${lessonId}/reward-badge/launch/`,
    submitBadgeReward: (lessonId: string) =>
      `/lessons/${lessonId}/reward-badge/submit/`,
    getBadgeRewards: (lessonId: string, student_id = '') =>
      `/lessons/${lessonId}/reward-badge/list/${
        student_id ? `${student_id}/` : ''
      }`,
    endBadgeReward: (lessonId: string) =>
      `/lessons/${lessonId}/reward-badge/end/`,
    getLeaderboard: (lessonId: string) => `/lessons/${lessonId}/leaderboard/`,
    getChestXPAmount: (lessonId: string) =>
      `/lessons/${lessonId}/reward-chest/`,
    launchPodium: (lessonId: string) => `/lessons/${lessonId}/launch-podium/`,
    notifyInactivity: (lessonId: string) => `/lessons/${lessonId}/inactivity/`,
    getTeacherReview: '/lessons/teacher-review/',
    submitTeacherReview: '/lessons/teacher-review/submit/',
    getStudentReview: '/lessons/student-review/',
    submitStudentReview: '/lessons/student-review/',
    removeStudentReview: '/lessons/student-review/',
    editStudentReview: '/lessons/student-review/',
    coBrowser: '/lessons/co-browser/',
    updateName: (lessonId: string) =>
      `/lessons/${lessonId}/change-name/`,
  },
  levels: {
    listStudentsLevel: '/levels/listStudentsLevel/',
  },
  profiles: {
    getBadges: '/profiles/won-badges/',
    getAttendance: '/profiles/attendance/',
    getPerformanceGraph: '/profiles/performance-graph/',
    setLastAcknowledgedLevel: '/auth/acknowledge-level/',
    getLastLessonXP: '/profiles/last-lesson-xp/',
  },
  pets: {
    setPetName: '/pets/',
    getPetInfo: '/pets/',
    revive: '/pets/revive/',
  },
  bigbluebutton: {
    create: '/bbb/create/',
    join: '/bbb/join/',
    end: '/bbb/end/',
    getMeetingInfo: '/bbb/info/',
    getRecordings: '/bbb/recordings/',
    insertDocument: (courseId: string) => `/bbb/insert-document/${courseId}/`,
    isMeetingRunning: (courseId: string) => `/bbb/is-meeting-running/${courseId}/`,
    deleteRecording: '/bbb/delete-recording/',
  },
  scheduling: {
    schedule: '/scheduling/',
    lessons: '/scheduling/lesson/',
  },
  upload: {
    presignedUrl: '/upload/presigned-url/',
    courseMaterial: '/upload/course-material/',
    quizImage: '/upload/quiz-image/',
    getCourseMaterial: '/upload/course-material/',
    // deleteCourseMaterial: '/upload/course-material/',
    removeCourseMaterial: '/upload/course-material/remove/',
    copyCourseMaterial: '/upload/course-material/copy/',
    updateCourseMaterial: '/upload/course-material/',
    homeworkAttachment: '/upload/homework-attachment/',
    deleteFile: '/upload/delete-file/',
    logo: '/upload/logo/',
  },
  collectibles: {
    getSummary: '/collectibles/summary/',
    assign: '/collectibles/assign/',
    use: '/collectibles/use/',
    leaderboard: '/collectibles/leaderboard/',
  },
  homework: {
    assign: '/homework/',
    get: '/homework/',
    edit: '/homework/',
    delete: '/homework/',
    archive: (assignmentId: string) => `/homework/${assignmentId}/archive/`,
    unarchive: (assignmentId: string) => `/homework/${assignmentId}/unarchive/`,
    postSubmission: '/homework/submission/',
    editSubmission: '/homework/submission/',
    getSubmission: '/homework/submission/',
    postFeedback: '/homework/feedback/',
    getFeedback: '/homework/feedback/',
    markFeedbackAsSeen: '/homework/feedback/mark-seen/',
  },
  notifications: {
    get: '/notifications/',
    markAsRead: '/notifications/mark-read/',
  },
  subscription: {
    getRenewSubscriptionLink: '/stripe/renewsubscription/',
  },
};

export default apiConfig;
